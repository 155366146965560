import React, { useMemo } from "react";
import "./Snow.css";

const Snow = () => {
  const snowflakes = useMemo(() => {
    return Array.from({ length: 50 }, () => ({
      size: `${Math.random() * 1.2 + 0.2}`,
      leftIni: `${Math.random() * 20 - 10}vw`,
      leftEnd: `${Math.random() * 20 - 10}vw`,
      left: `${Math.random() * 100}vw`,
      duration: `${Math.random() * 10 + 5}s`,
      delay: `${Math.random() * -10}s`,
      blur: `${Math.random() * 3}px`,
    }));
  }, []);

  return (
    <div className="snow-container">
      {snowflakes.map((flake, index) => (
        <div
          key={index}
          className="snowflake"
          style={
            {
              left: flake.left,
              filter: `blur(${flake.blur})`,
              animationDuration: flake.duration,
              animationDelay: flake.delay,
              "--left-ini": flake.leftIni,
              "--left-end": flake.leftEnd,
            } as React.CSSProperties
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth="1.5"
            strokeLinecap="round"
            width={`${+flake.size * 24}px`}
            height={`${+flake.size * 24}px`}
          >
            <path d="M12 2V18M12 22V18M12 18L15 21M12 18L9 21M15 3L12 6L9 3" />
            <path d="M3.34 7L6.8 9M6.8 9L17.2 15M6.8 9L5.7 4.9M6.8 9L2.7 10.1M17.2 15L20.66 17M17.2 15L21.29 13.9M17.2 15L18.29 19.1" />
            <path d="M20.66 7L17.2 9M17.2 9L6.8 15M17.2 9L18.29 4.9M17.2 9L21.29 10.1M6.8 15L3.34 17M6.8 15L2.7 13.9M6.8 15L5.7 19.1" />
          </svg>
        </div>
      ))}
    </div>
  );
};

export default React.memo(Snow);
