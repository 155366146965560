import React from "react";
import "./Heart.css"

type Props = {
  x: number;
  y: number;
  direction: string;
  isRemote: boolean;
};

const Heart = ({ x, y, direction, isRemote }: Props) => {
  const classes = `heart heart${direction} ${isRemote ? "remoteHeart" : ""}`;

  return (
    <div className="heartContainer" style={{ left: x, top: y }}>
      <div className={classes}></div>
    </div>
  );
};

export default Heart;
